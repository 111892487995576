import React, { useEffect, useState } from 'react'
import { IAcceptAll } from '../../common/interfaces'
import { HashRouter, Switch, Route, Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import WebHeader from '../webpage/web-header';
import { IsLoggedIn, ValueFromUserData, SetLocalStorage } from "../../common/utility";
import RecoverPassword from '../../views/PublicPages/RecoverPassword/RecoverPassword';
import SignupLayout from '../../views/PublicPages/userWebPages/signup/signupLayout';
import Signup from '../../views/PublicPages/client/Signup/Signup';
import WebFooter from '../webpage/web-footer'; 
import SignUp2 from '../../views/PublicPages/client/Signup/SignUp2';
import SignUp3 from '../../views/PublicPages/client/Signup/SignUp3';
import SignUp4 from '../../views/PublicPages/client/Signup/SignUp4';
import SignUp5 from '../../views/PublicPages/client/Signup/SignUp5';
import SignUp6 from '../../views/PublicPages/client/Signup/SignUp6';
import SignUp7 from '../../views/PublicPages/client/Signup/SignUp7';
import Signupcommon from '../../views/PublicPages/client/Signup/Signupcommon';





// Containers
const ClientLayout = React.lazy(() => import("../../views/PublicPages/client/clientLayout"));
const AdminLayout = React.lazy(() => import("../../views/PublicPages/admin/adminLayout"));


// Pages
const Login = React.lazy(() => import("../../views/PublicPages/Login/Login"));

const Homepage = React.lazy(
  () => import("../../views/PublicPages/userWebPages/home/homepage")
);

const UserLayout = React.lazy(() => import('../../views/PublicPages/userWebPages/userAuthPages/userLayout'))
const AppLayout: React.FC<IAcceptAll> = props => {
    const history = useHistory();
    const location = useLocation()
    const loading = (
        <div className="pt-3 text-center">
          <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
      );
    const [canShowHeader, setCanShowHeader] = useState(false)
    useEffect(() => {
        if(history.location.pathname !== '/login' && history.location.pathname !== '/client/signup') {
            setCanShowHeader(true);
        } else {
          setCanShowHeader(false);
        }
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [history.location.pathname])

    useEffect(() => {
      const signupId = location.search;
      const searchApi = new URLSearchParams(signupId);
      if(searchApi.has('refCode')) {
        SetLocalStorage('referer', searchApi.get('refCode') as string)
      }
      
    }, [])

    return (
        <div className="app-layout">
            { canShowHeader && <WebHeader isAdmin={IsLoggedIn() && ValueFromUserData('roleName') === 'ADMIN'} isClient={IsLoggedIn() && ValueFromUserData('roleName') === 'CLIENT'}  showAuthRoutes={IsLoggedIn() && ValueFromUserData('roleName') === 'USER'} /> }


            <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/client/signup"
              render={(props) => <Signupcommon />}
              // render={(props) => <Signup {...props} />}
            />
             {/* <Route
              exact
              path="/client/commonsignup"
              render={(props) => <Signup {...props} />}
            /> */}

            {/* <Route
              exact
              path="/client/signup2"
              render={(props) => <SignUp2/>}
            /> */}
             {/* <Route
              exact
              path="/client/signup3"
              render={(props) => <SignUp3/>}
            />
             <Route
              exact
              path="/client/signup4"
              render={(props) => <SignUp4/>}
            />
            <Route
              exact
              path="/client/signup5"
              render={(props) => <SignUp5/>}
            />
             <Route
              exact
              path="/client/signup6"
              render={(props) => <SignUp6/>}
            />
             <Route
              exact
              path="/client/signup7"
              render={(props) => <SignUp7/>}
            /> */}
            <Route
              exact={false}
              path="/signup"
              render={(props) => <SignupLayout {...props} />}
            />


            <Route
              exact
              path="/recover-password"
              render={(props) => <RecoverPassword {...props} />}
            />
            

            <Route path="/user" render={(props) => IsLoggedIn() && ValueFromUserData('roleName') === 'USER' ? <UserLayout {...props} /> : <Redirect to={"/login" + location.pathname.includes('login') ? '' : location.pathname} />} />
            <Route
              path="/dashboard"
              render={(props) =>
                IsLoggedIn() && ValueFromUserData('roleName') === 'CLIENT' ? <ClientLayout {...props} /> : <Redirect to="/login" />
              }
            />
            <Route
              path="/admin"
              render={(props) =>
                IsLoggedIn() && ValueFromUserData('roleName') === 'ADMIN' ? <AdminLayout {...props} /> : <Redirect to="/login" />
              }
            />
            <Route exact path="/" render={(props) => <Homepage {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      { canShowHeader && <WebFooter /> }

        </div>
    )
}

export default AppLayout
import React, { useState } from "react";
import {
  IApiCallStatus,
  ISelectOption,
} from "../../../../common/interfaces";
import { useHistory } from "react-router";
import InputCtrl from "../../../../controls/InputCtrl";
import { Link } from "react-router-dom";
import SelectCtrl from "../../../../controls/SelectCtrl";

const genderOptions: ISelectOption[] = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const Signup = ({ control, errorMessage, error }: any) => {
  const history = useHistory();
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: "",
  });
  return (
    <>
      <div className="signup-header">
        <h3>Sign Up</h3>
        <span>
          <strong>   Step 1-7</strong>
        </span>
      </div>
      <div className="row">
        <div className="col-md-10">
          <h5>Don't have an account. Create an account. It takes less than a minute</h5>
        </div>
      </div>
      <InputCtrl
        control={control}
        showError={errorMessage}
        type="text"
        placeholder="First Name"
        name="firstName"
        required={true}
        className="form-control"
        componentName="signup-survey"
        disabled={ApiStaus.InProgress}
        float={true}
        id="firstName"
        onInput={(e: any) => {
          let inputValue = e.target.value;
          // Ensure the first character is a letter and the rest are digits, maximum length 10
          inputValue = inputValue.replace(/[^A-Za-z]/g, '');
          e.target.value = inputValue;
        }}
      />
      <InputCtrl
        control={control}
        showError={errorMessage}
        type="text"
        placeholder="Last Name"
        name="lastName"
        required={true}
        className="form-control"
        componentName="signup-survey"
        disabled={ApiStaus.InProgress}
        float={true}
        onInput={(e: any) => {
          let inputValue = e.target.value;
          // Ensure the first character is a letter and the rest are digits, maximum length 10
          inputValue = inputValue.replace(/[^A-Za-z]/g, '');
          e.target.value = inputValue;
        }}
      />
      <InputCtrl
        control={control}
        showError={errorMessage}
        type="email"
        placeholder="Email"
        name="email"
        required={true}
        className="form-control"
        float={true}
        componentName="signup"
        disabled={ApiStaus.InProgress}
         />
      <InputCtrl
        control={control}
        showError={errorMessage}
        type="text"
        placeholder="Phone Number"
        name="phoneNumber"
        required={true}
        className="form-control"
        float={true}
        componentName="signup"
        disabled={ApiStaus.InProgress}
        onInput={(e: any) => {
          let inputValue = e.target.value;
          // Ensure the first character is a letter and the rest are digits, maximum length 10
          inputValue = inputValue.replace(/[^+0-9]/g, '').substring(0, 12);
          e.target.value = inputValue;
        }}
      />
      <div className="controller-outer">
        <div className="form-floating">
          <InputCtrl
            showError={errorMessage}
            control={control}
            type="password"
            required={true}
            placeholder="Password"
            name="password"
            className="form-control"
            disabled={ApiStaus.InProgress}
          />
          {errorMessage && errorMessage.password ? errorMessage("password") : null}
        </div>
      </div>
      <div className="controller-outer">
        <div className="form-floating">
          <div className="">
            <InputCtrl
              control={control}
              showError={errorMessage}
              type="password"
              placeholder="Confirm Password"
              name="cPassword"
              required={true}
              className="form-control"
              componentName="login"
              disabled={ApiStaus.InProgress}
            />
            {errorMessage && errorMessage.cPassword ? errorMessage("cPassword") : null}
            <div className="text-red">
              {error}
            </div>
          </div>
        </div>
        {ApiStaus.InProgress || ApiStaus.Failed ? (
          <div className="form-check">
            <label className="text-danger">
              {ApiStaus.InProgress
                ? "Please wait..."
                : ApiStaus.FailMessage}
            </label>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Signup;

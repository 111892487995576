import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputCtrl from "../../../../controls/InputCtrl";
import { useForm } from "react-hook-form";
import { IApiCallStatus, ISelectOption } from "../../../../common/interfaces";
import SelectCtrl from "../../../../controls/SelectCtrl";
import { IsLoggedIn } from "../../../../common/utility";
import { GetAllQuestions } from "../../../../common/apiServices";

const SignUp2 = ({ control, errorMessage, setSignup }: any) => {
  const history = useHistory();

  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: "",
  });
  const identity = [
    "Male",
    "Female",
    "Non-Binary/gender Non-conforming/ Another identity  not  listed",
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <h5>Sign Up</h5>
                <h5>Step 2-7</h5>
              </div>
              <h6 className="my-3">
                To which of the following gender identities do you most
                closely identity?
              </h6>
              <div className="mb-3"></div>
              {identity.map((item, index) => (
                <div key={index} className="control mb-3">
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    name={item}
                    value={item}
                  />
                  {/* <InputCtrl
                    control={control}
                    showError={errorMessage}
                    type="checkbox"
                    placeholder="Age"
                    name={item}
                    required={true}
                    className="control"
                    componentName="login"
                    disabled={ApiStaus.InProgress}
                    // d={item}
                  /> */}
                  {item}
                </div>
              ))}
              <h6 className="my-3">What is your age?</h6>
              <InputCtrl
                control={control}
                showError={errorMessage}
                type="input"
                placeholder="Age"
                name="age"
                required={true}
                className="control"
                componentName="login"
                maxLength={3}
                onInput={(e: any) => {
                  const inputValue = Math.min(parseInt(e.target.value) || 0, 126); // Ensure the value is less than or equal to 3
                  e.target.value = inputValue.toString().slice(0, 3); // Limit to one digit
              }}
                disabled={ApiStaus.InProgress}
              />
              <h6 className="">What is your DOB?</h6>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="mb-3">Month</h6>
                  <InputCtrl
                    control={control}
                    showError={errorMessage}
                    type="text"
                    placeholder="MM"
                    name="month"
                    required={true}
                    maxLength={2}
                    className="control "
                    onInput={(e: any) => {
                      const inputValue = Math.min(parseInt(e.target.value) || 0, 12); // Ensure the value is less than or equal to 3
                      e.target.value = inputValue.toString().slice(0, 2); // Limit to one digit
                  }}
                    componentName="login"
                    disabled={ApiStaus.InProgress}
                  />
                </div>
                <div className="col-md-4">
                  <h6 className="mb-3">Day</h6>
                  <InputCtrl
                    control={control}
                    showError={errorMessage}
                    type="text"
                    placeholder="DD"
                    name="day"
                    required={true}
                    className="control mb-6 "
                    componentName="login"
                    disabled={ApiStaus.InProgress}
                    onInput={(e: any) => {
                      const inputValue = Math.min(parseInt(e.target.value) || 0, 31); // Ensure the value is less than or equal to 3
                      e.target.value = inputValue.toString().slice(0, 2); // Limit to one digit
                  }}
                  />
                </div>
                <div className="col-md-4">
                  <h6 className="mb-3">Year</h6>
                  <InputCtrl
                    control={control}
                    showError={errorMessage}
                    type="text"
                    placeholder="YYYY"
                    name="year"
                    required={true}
                    className="control mb-6 "
                    componentName="login"
                    maxLength={4}
                    onInput={(e: any) => {
                      const inputValue = Math.min(parseInt(e.target.value) || 0, 9999); // Ensure the value is less than or equal to 3
                      e.target.value = inputValue.toString().slice(0, 4); // Limit to one digit
                  }}
                    disabled={ApiStaus.InProgress}
                  />
                </div>
              </div>
              <h6 className="mb-3">What is your pincode?</h6>
              <InputCtrl
                control={control}
                showError={errorMessage}
                type="text"
                placeholder="12345"
                name="pinCode"
                required={true}
                className="control"
                componentName="login"
                disabled={ApiStaus.InProgress}
                onInput={(e: any) => {
                  const inputValue = Math.min(parseInt(e.target.value) || 0, 99999); // Ensure the value is less than or equal to 3
                  e.target.value = inputValue.toString().slice(0, 5); // Limit to one digit
              }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp2;

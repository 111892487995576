import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { IApiCallStatus } from "../../../../common/interfaces";
import { GetAllQuestions, PostData } from "../../../../common/apiServices";
import { questinsData } from "../../../../common/data";
import SelectCtrl from "../../../../controls/SelectCtrl";

const SignUp7 = ({ control, errorMessage, updateQuestionAnswer }: any) => {
  const history = useHistory();
  const [questions, SetQuestions] = useState<any>([]);
  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: "",
  });
  const fetchSignupQuestins = async () => {
    SetApiStatus({ ...ApiStaus, InProgress: true });

    try {
      const resp = await GetAllQuestions(`Question/GetAll`, 1, 20, 4);
      if (resp.data) {
        SetQuestions(resp.data.items.map((question: any) => { return { ...question, answer: "" } }))
      }
      SetApiStatus({ ...ApiStaus, InProgress: false });
    } catch (err) {
      console.log(err);
    }
  };
  const LoadOptions = (item: any, index: number) => {
    try {
      const foundOptions = questinsData.find((el :any) => el?.id === item?.id
      );
      if (foundOptions) {
        return (
          <SelectCtrl
            control={control}
            showError={errorMessage}
            options={foundOptions?.answer?.map((questions: any) => {
              return {
                value: questions.value,
                label: questions.label
              }
            })}
            placeholder=" Select Answers"
            name={foundOptions.id.toString()}
            required={true}
            className="form-control"
            disabled={ApiStaus.InProgress}
          />
        );
      }
    } catch (error) {
      console.log("error", error);
    }
    return null;
  };
  useEffect(() => {
    fetchSignupQuestins();
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <h5>Sign Up</h5>
                <h5>Step 7/7 </h5>
              </div>
              {questions.map((item: any, index: number) => {
                return (
                  <>
                    <h5 className="my-3">{item.question}</h5>
                    {LoadOptions(item, index)}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp7;

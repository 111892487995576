/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useState, useEffect } from "react";
import styles from "./webpage.module.scss";
import { Link, useHistory } from "react-router-dom";
import {
  IsLoggedIn,
  UserDataKey,
  RemoveFromLocalStorage,
  ValueFromUserData,
  createCookie,
} from "../../common/utility";

const navItems = [
  {
    name: "Contact",
    target: "#",
    isLogged: false,
    isAdmin: false,
    clickable: () => {
      window.location.href = window.runConfig["wordpressUrl"] + "contact";
    },
  },
  {
    name: "Blog",
    target: "#",
    isLogged: false,
    isAdmin: false,
    clickable: () => {
      window.location.href = window.runConfig["wordpressUrl"];
    },
  },
  {
    name: "FAQ",
    target: "#",
    isLogged: false,
    isAdmin: false,
    clickable: () => {
      window.location.href =
        window.runConfig["wordpressUrl"] + "frequently-asked-questions/";
    },
  },
  {
    name: "Home",
    target: "#/user/home",
    isLogged: true,
    isAdmin: false,
  },
  {
    name: "Surveys",
    target: "#/user/surveys",
    isLogged: true,
    isAdmin: false,
  },
  {
    name: "Referrals",
    target: "#/user/referrals",
    isLogged: true,
    isAdmin: false,
  },
  {
    name: "Settings",
    target: "#/user/settings",
    isLogged: true,
    isAdmin: false,
  },
  {
    name: "Home",
    target: "#/dashboard/home",
    isLogged: true,
    isAdmin: true,
  },
  {
    name: "Surveys",
    target: "#/dashboard/surveys",
    isLogged: true,
    isAdmin: true,
  },
  {
    name: "Settings",
    target: "#/dashboard/settings",
    isLogged: true,
    isAdmin: true,
  },
  {
    name: "Redeem",
    target: "javascript:",
    isLogged: true,
    isAdmin: false,
    clickable: () => {
      createCookie("token", ValueFromUserData("token"));
      window.location.href = window.runConfig["wordpressUrl"] + "shop/";
    },
  },
];
const debounceTime = 300;
let distinctUntilChangeTimeout: any;
export const WebHeader: React.FC<{
  showAuthRoutes: boolean;
  isClient: boolean;
  isAdmin: boolean;
}> = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const history = useHistory();
  const [name, setName] = useState({
    first: "",
    last: "",
  });
  const onSearched = (event: any) => {
    const value = event.target.value;
    if (distinctUntilChangeTimeout) {
      clearTimeout(distinctUntilChangeTimeout);
    }
    distinctUntilChangeTimeout = setTimeout(() => {
      history.push("/user/home?query=" + value);
    }, debounceTime);
  };
  const IsActive = (url: string) => {
    if (history.location.pathname.includes(url.slice(1))) {
      return true;
    }
    return false;
  };
  const LogoutUser = () => {
    RemoveFromLocalStorage(UserDataKey);
    history.push("/login");
  };
  const [imgSrc, setImgSrc] = useState({ src: "", error: false });

  useEffect(() => {
    // alert(ValueFromUserData('fileUrl'))
    setImgSrc({
      ...imgSrc,
      src: ValueFromUserData("fileUrl") || "sample.jpg",
    });
    setName({
      first: ValueFromUserData("firstName"),
      last: ValueFromUserData("lastName"),
    });
    window["reloadHeader"] = () => {
      const firstName = ValueFromUserData("firstName");
      const lastName = ValueFromUserData("lastName");
      const fileUrl = ValueFromUserData("fileUrl") || "sample";
      setName({ first: firstName, last: lastName });
      setImgSrc({ error: false, src: fileUrl });
    };
  }, []);
  return (
    <Fragment>
      <div className={styles.navbarHome}>
        {/* upper black portion */}
        <div className={styles.navUpperHeader}>
          Earn Online Cash Rewards with Grapevine
        </div>
        <nav className={styles.headerNav}>
          <ul className={styles.listUI}>
            <li
              className={`${styles.navItem} ${styles.navBrand}`}
            >
              <Link to="/">
                <img src="/images/logo.svg" alt="brand" />
              </Link>
            </li>
            {props.isAdmin ? (
              <>
                  <li style={{ marginRight: "auto" }} className={styles.navItem}>
                  <a
                    href="#/admin/users"
                    className={`${
                      IsActive("/admin/users") ? styles.selected : ""
                    }`}
                  >
                    Users
                  </a>
                </li>
                <li style={{ marginRight: "auto" }} className={styles.navItem}>
                  <a
                    href="#/admin/surveys"
                    className={`${
                      IsActive("/admin/surveys") ? styles.selected : ""
                    }`}
                  >
                    Surveys
                  </a>
                </li>
                <li style={{ flex: 1 }}></li>
                {IsLoggedIn() ? (
                  <>
                    <li className={styles.navitem}>
                      <span className="profile-img-show">
                        <img
                          style={{
                            width: "40px",
                            border: "1px solid",
                            borderRadius: "50%",
                          }}
                          src={imgSrc.src}
                          onError={() =>
                            setImgSrc({
                              src:
                                "/images/user-icon-image-placeholder-300-grey.jpg",
                              error: true,
                            })
                          }
                        />
                      </span>
                    </li>
                    <li className={`${styles.navItem}`}>
                      
                      <div className="dropdown">
                        <div
                          className="dropdown-toggle"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button"
                        >
                          <label
                            style={{ margin: 0 }}
                          >{`${name.first} ${name.last}`}</label>
                        </div>
                        <div
                          onClick={LogoutUser}
                          className="dropdown-menu hand"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a href="javascript:">Logout</a>
                        </div>
                      </div>
                      <span
                        className="badge"
                        style={{ background: "#6a3494", borderRadius: "21px" }}
                      >{`${ValueFromUserData("roleName")}`}</span>
                    </li>
                  </>
                ) : (
                  <>
                    <li className={`${styles.navItem} ${styles.signin} `}>
                      <Link to="/login">Sign In</Link>
                    </li>

                    <li className={`${styles.navItem} ${styles.signup}`}>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  </>
                )}
              </>
            ) : (
              <>
                {props.isClient
                  ? navItems
                      .filter((val) => val.isLogged && val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            key={idx}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })
                  : IsLoggedIn() && !props.isClient && props.showAuthRoutes
                  ? navItems
                      .filter((val) => val.isLogged && !val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })
                  : navItems
                      .filter((val) => !val.isLogged && !val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })}
                {props.isClient ||
                (IsLoggedIn() && !props.isClient && props.showAuthRoutes) ? (
                  <>
                  <li style={{ flex: 1 }}></li>
                    <li className={`${styles.navItem}`}>
                      <label>
                        <input
                          onChange={onSearched}
                          type="text"
                          placeholder={`${
                            props.isClient ? "Search" : "Search & Earn"
                          }`}
                        />
                        <span>
                            <img src="/images/loupe (13) 1.png" alt="" />
                          </span>
                      </label>
                    </li>
                    <li className={styles.navitem}>
                      <span className="profile-img-show">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            border: "1px solid",
                            borderRadius: "50%",
                          }}
                          src={imgSrc.src}
                          onError={() =>
                            setImgSrc({
                              src:
                                "/images/user-icon-image-placeholder-300-grey.jpg",
                              error: true,
                            })
                          }
                        />
                      </span>
                    </li>
                
                    <li className={`${styles.navItem}`}>
                      <div className="dropdown" role="button">
                        <div
                          className="dropdown-toggle"
                          id="dropdownMenuButton"
                          aria-expanded="false"
                          data-bs-toggle="dropdown" 
                        >
                          <label
                            style={{ margin: 0 ,cursor :'pointer'}}
                          >{`${name.first} ${name.last}`}</label>
                        </div>
                        <div
                          onClick={LogoutUser}
                          className="dropdown-menu "
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a href="javascript:">Logout</a>
                        </div>
                      </div>
                      <span
                        className="badge"
                        style={{ background: "#6a3494", borderRadius: "21px" }}
                      >{`${ValueFromUserData("roleName")}`}</span>
                    </li>
                  </>
                ) : (
                  <>
                  <li style={{ flex: 1 }}></li>
                    <li className={`${styles.navItem} ${styles.signin} `}>
                      <Link to="/login">Sign In</Link>
                    </li>

                    <li className={`${styles.navItem} ${styles.signup}`}>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>

          {props.isAdmin ? null : (
            <>
              {/* for mobile view */}

              <div className={styles.listMob}>
                <Link to="/">
                  <img src="/images/logo.svg" alt="" />
                </Link>
                {/* <h3>Paid Survey App</h3> */}
                <span
                  onClick={() => setNavOpen(!navOpen)}
                  className={`${styles.toggle} ${navOpen ? styles.open : ""}`}
                ></span>
              </div>
              <ul
                className={`${styles.listMobList} ${
                  navOpen ? styles.listMobListOpen : ""
                }`}
              >
                {/* <li className={`${styles.navItem} ${styles.navBrand}`} style={{flexGrow: 0.4}}>
              <img src="/images/GVArtboard 4 copy 6 2.png" alt="brand" />
            </li> */}

                {props.isClient
                  ? navItems
                      .filter((val) => val.isLogged && val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            key={idx}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })
                  : IsLoggedIn() && !props.isClient && props.showAuthRoutes
                  ? navItems
                      .filter((val) => val.isLogged && !val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })
                  : navItems
                      .filter((val) => !val.isLogged && !val.isAdmin)
                      .map((val, idx) => {
                        return (
                          <li
                            className={styles.navItem}
                            onClick={val.clickable ? val.clickable : () => {}}
                          >
                            <a
                              href={val.target}
                              className={`${
                                IsActive(val.name.toLowerCase())
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              {val.name}
                            </a>
                          </li>
                        );
                      })}
                {props.isClient ||
                (IsLoggedIn() && !props.isClient && props.showAuthRoutes) ? (
                  <>
                    <li className={`${styles.navItem}`}>
                      <label>
                        <input
                          type="text"
                          placeholder={`${
                            props.isClient ? "Search" : "Search & Earn"
                          }`}
                        />
                        <span>
                            <img src="/images/loupe (13) 1.png" alt="" />
                          </span>
                      </label>
                    </li>
                    {/* <li className={styles.navitem}>
                    <span className="profile-img-show">
                      <img style={{
                        width: '40px',
                        border: '1px solid',
                        borderRadius: '50%'
                      }} src={imgSrc.src} onError={() => setImgSrc({ src: '/images/user-icon-image-placeholder-300-grey.jpg', error: true })} />
                    </span>
                    <span className="badge" style={{ background: '#6a3494', borderRadius: '21px' }}>{`${ValueFromUserData('roleName')}`}</span>

                  </li> */}
                    <li className={`${styles.navItem}`}>
                      {/* <div className="dropdown">
                      <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <label style={{ margin: 0 }}>{`${ValueFromUserData('firstName')} ${ValueFromUserData('lastName')}`}</label>
                      </div>
                      <div onClick={LogoutUser} className="dropdown-menu hand" aria-labelledby="dropdownMenuButton">

                        <a href="javascript:">Logout</a>
                      </div>
                    </div> */}

                      <a href="javascript:" onClick={LogoutUser}>
                        Logout
                      </a>
                      {/* <li className={`${styles.navItem}`}>
                    </li> */}
                    </li>
                  </>
                ) : (
                  <>
                    <li className={`${styles.navItem} ${styles.signin} `}>
                      <Link to="/login">Sign In</Link>
                    </li>

                    <li className={`${styles.navItem} ${styles.signup}`}>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  </>
                )}

                {/* {IsLoggedIn() && props.showAuthRoutes ? navItems.filter(val => val.isLogged).map((val, idx) => {
              return (
                <li className={styles.navItem}>
                  <a href={val.target} className={`${IsActive(val.name.toLowerCase()) ? styles.selected : ''}`}>{val.name}</a>
                </li>
              );
            }) : navItems.filter(val => !val.isLogged).map((val, idx) => {
              return (
                <li className={styles.navItem}>
                  <a href={val.target} className={`${IsActive(val.name.toLowerCase()) ? styles.selected : ''}`}>{val.name}</a>
                </li>
              );
            })} */}
                {/* {
              IsLoggedIn() && props.showAuthRoutes ? (


                <>
                  <li className={`${styles.navItem}`}>
                    <label>
                      <input type="text" placeholder="Search & Earn" />
                      <i className="fas fa-search"></i>
                    </label>
                  </li>

                  <li className={`${styles.navItem}`}>
                    <a href="javascript:" onClick={LogoutUser}>Logout</a>
                  </li>
                </>
              ) : (
                  <>
                    <li className={`${styles.navItem} ${styles.signin} `}>
                      <Link to="/login">Sign In</Link>
                    </li>

                    <li className={`${styles.navItem} ${styles.signup}`}>
                      <Link to="/signup">Sign Up</Link>

                    </li>
                  </>
                )
            } */}
              </ul>
            </>
          )}
        </nav>
      </div>
    </Fragment>
  );
};

export default WebHeader;
